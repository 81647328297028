
  import { UserInfo } from '@firebase/auth-types'
  import { Component } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import initials from 'initials'
  import { fixText } from '@/utils/general'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { Crumb } from '@/store/resources/form/state'
  import { PersonFormatted } from '@/store/persons/person'
  import LogOut from '@/components/LogOut.vue'
  import { Role } from '@/store/user/models'

@Component({
  components: { LogOut },
  computed: {
    ...mapGetters('user', ['isLoggedIn', 'user', 'rolesName', 'roles']),
    ...mapGetters('app', ['isMini']),
    ...mapGetters('resources/form', ['crumbs']),
  },
  methods: {
    ...mapMutations('resources/form', ['setBackup']),
    ...mapActions('user', ['logout']),
    ...mapMutations('persons', ['setFoundPerson', 'setResetPhone', 'setRutInfo', 'setRutError']),
    ...mapActions('resources/form', ['setCrumbs']),
  },
})
  export default class ActiveUser extends FilesProcess {
  setCrumbs!: (crumbs: Array<Crumb>) => void
  setFoundPerson!: (person: PersonFormatted | null) => void;
  setResetPhone!: (phone) => void
  setRutInfo!: (data: undefined) => void;
  setRutError!: (data: boolean) => void;
  setBackup!: (payload) => void
  logout!: () => void;
  crumbs: Array<any>
  roles!: Array<Role>
  public isLoggedIn!: boolean;
  public user!: UserInfo;
  isMini!: boolean;
  menu = false

  get initials (): string | string[] {
    const { displayName, email } = this.user
    return initials((displayName as string) || (email as string))
  }

  get isSysOps () {
    const { roles } = this

    return roles.some(role => role.slug === 'sysop')
  }

  truncateText (val: string): string {
    return fixText(val)
  }

  async openEnterprise () {
    const { isSysOps } = this
    if (this.$route.path.includes('enterprise') || !isSysOps || this.$route.path.includes('person')) {
      return
    }

    this.setCrumbs([])
    this.initValues()
    const { metadata } = this.getForm('Person', 'person')
    const user = await this.fetchData({
      query: { name: 'findLite', model: 'User' },
      filter: { id: { _eq: 1 } },
    })

    const person = await this.fetchData({
      query: { name: 'findLite', model: 'Person' },
      filter: { user: { id: { _eq: user[0].id } } },
    })

    this.setCustomCrumbs(metadata, 'Editar Empresa')

    await this.$router.push({
      name: 'generic-person',
      params: { uid: person[0].id },
    }).catch(() => {
    })
  }

  async openPerson () {
    if (this.$route.path.includes('person')) {
      return
    }
    this.setCrumbs([])
    this.initValues()
    const { metadata } = this.getForm('Person', 'person')
    const user = await this.fetchData({
      query: { name: 'findLite', model: 'User' },
      filter: { email: { _eq: this.user.email } },
    })

    const person = await this.fetchData({
      query: { name: 'findLite', model: 'Person' },
      filter: { user: { id: { _eq: user[0].id } } },
    })

    this.setCustomCrumbs(metadata, 'Editar Persona')
    await this.$router.push({
      name: 'generic-person',
      params: { uid: person[0].id },
    }).catch(() => {
    })
  }

  setCustomCrumbs (metadata, title) {
    this.setCrumbs([{ resource: metadata, title }])
  }

  initValues () {
    this.setFoundPerson(null)
    this.setResetPhone(null)
    this.setRutInfo(null)
    this.setRutError(false)
    this.setBackup(null)
  }

  signOut (): void {
    this.logout()
  }
  }
